<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleUser">
                    <el-form-item label='序号' prop='id' :label-width="formLabelWidth" v-if="false">
              <el-input placeholder='请输入序号' clearable autocomplete="off" v-model='form.id' size="small"  />
            </el-form-item>
            <el-form-item label='订单id' prop='orderid' :label-width="formLabelWidth">
              <el-input placeholder='请输入订单id' clearable autocomplete="off" v-model='form.orderid' size="small"  />
            </el-form-item>
            <el-form-item label='用户id' prop='userid' :label-width="formLabelWidth">
              <el-input placeholder='请输入用户id' clearable autocomplete="off" v-model='form.userid' size="small"  />
            </el-form-item>
            <el-form-item label='用户名' prop='username' :label-width="formLabelWidth">
              <el-input placeholder='请输入用户名' clearable autocomplete="off" v-model='form.username' size="small"  />
            </el-form-item>
            <el-form-item label='密码' prop='password' :label-width="formLabelWidth">
              <el-input placeholder='请输入密码' clearable autocomplete="off" v-model='form.password' size="small"  />
            </el-form-item>
            <el-form-item label='有效期' prop='expiration' :label-width="formLabelWidth">
                <el-date-picker
                        v-model="form.expiration"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择到期时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label='国家' prop='country' :label-width="formLabelWidth">
                <el-select v-model="form.country" placeholder="请选择" filterable>
                    <el-option
                            v-for="item in countryList"
                            :key="item.twocode"
                            :label="item.shortname"
                            :value="item.twocode">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='负责人' prop='charge' :label-width="formLabelWidth">
              <el-input placeholder='请输入负责人' clearable autocomplete="off" v-model='form.charge' size="small"  />
            </el-form-item>
            <el-form-item label='邮箱' prop='email' :label-width="formLabelWidth">
              <el-input placeholder='请输入邮箱' clearable autocomplete="off" v-model='form.email' size="small"  />
            </el-form-item>
            <el-form-item label='商品类型' prop='type' :label-width="formLabelWidth">
              <el-input placeholder='请输入商品类型' clearable autocomplete="off" v-model='form.type' size="small"  />
            </el-form-item>
            <el-form-item label='续费月数' prop='month' :label-width="formLabelWidth">
                <el-input-number placeholder='请输入续费月数' clearable autocomplete="off" v-model='form.month' :precision="0" :step="1" :min="1"></el-input-number>
            </el-form-item>
            <el-form-item label='是否自动续费' prop='autorenew' :label-width="formLabelWidth">
                <el-select v-model="form.autorenew" placeholder="请选择">
                    <el-option
                            v-for="item in isOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='到期时间' prop='endtime' :label-width="formLabelWidth">
                <el-date-picker
                        v-model="form.endtime"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择到期时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label='更换套餐时间' prop='changetime' :label-width="formLabelWidth">
                <el-date-picker
                        v-model="form.changetime"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label='备注' prop='remark' :label-width="formLabelWidth">
              <el-input type="textarea" :rows="4" placeholder='请输入备注' clearable autocomplete="off" v-model='form.remark' size="small"  />
            </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { insertCarbuy,updateCarbuy,deleteCarbuy,bacthDelCarbuy,getCountryinfoAll } from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo } from "@/util/util"
  export default {
    name: "user",
    data(){
      const isOptions=[
            {
                value:"1",
                label:"是",
            },
            {
                value:"0",
                label:"否",
            },
      ]
      const columns = [
          {type: 'selection'},
          {prop: 'id',label: '序号'},
          {prop: 'orderid',label: '订单id'},
          {prop: 'userid',label: '用户id'},
          {prop: 'username',label: '用户名'},
          {prop: 'password',label: '密码'},
          {prop: 'expiration',label: '有效期'},
          {prop: 'country',label: '国家'},
          {prop: 'charge',label: '负责人'},
          {prop: 'email',label: '邮箱'},
          {prop: 'type',label: '商品类型'},
          {prop: 'month',label: '续费月数'},
          {prop: 'autorenew',label: '是否自动续费'},
          {prop: 'endtime',label: '到期时间'},
          {prop: 'changetime',label: '下次更换套餐时间'},
          {prop: 'remark',label: '备注'},
          {prop: 'createTime',label: '创建时间'},
          {prop: 'updateTime',label: '更新时间'},
      ]
      const searchForm = [
          {
            type: 'input',
            id: 'orderid',
            label: '订单id',
            el: {placeholder: '请输入订单id', clearable: true},
          },
          {
            type: 'input',
            id: 'userid',
            label: '用户id',
            el: {placeholder: '请输入用户id', clearable: true},
          },
          {
            type: 'input',
            id: 'username',
            label: '用户名',
            el: {placeholder: '请输入用户名', clearable: true},
          },
          {
            type: 'input',
            id: 'password',
            label: '密码',
            el: {placeholder: '请输入密码', clearable: true},
          },
          {
            type: 'input',
            id: 'country',
            label: '国家',
            el: {placeholder: '请输入国家', clearable: true},
          },
          {
            type: 'input',
            id: 'charge',
            label: '负责人',
            el: {placeholder: '请输入负责人', clearable: true},
          },
          {
            type: 'input',
            id: 'email',
            label: '邮箱',
            el: {placeholder: '请输入邮箱', clearable: true},
          },
          {
            type: 'input',
            id: 'type',
            label: '商品类型',
            el: {placeholder: '请输入商品类型', clearable: true},
          },
          {
            type: 'input',
            id: 'autorenew',
            label: '是否自动续费',
            el: {placeholder: '请输入是否自动续费', clearable: true},
          },
          {
            type: 'input',
            id: 'remark',
            label: '备注',
            el: {placeholder: '请输入备注', clearable: true},
          },
      ]
      return {
        columns,
        isOptions,
        countryList:[],
        userinfo:{},
        tableConfig: {
          searchForm,
          url: 'carbuy/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('carbuy:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '删除',
              icon: 'el-icon-delete',
              show:()=>{return this.hasRole('carbuy:delete')},
              atClick: async row => {
                this.handleDelete(row)
                return false
              },
            },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('carbuy:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            {
              text: '批量删除',
              type: 'primary',
              show:()=>{return this.hasRole('carbuy:batchDelete')},
              disabled: selected => selected.length < 1,
              atClick: selected => {
                let ids = selected.map(item => item.id)
                this.Batch('delete', ids)
                return false
              },
            },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        title:"",
        type:"",
        dialogFormVisible:false,
        formLabelWidth:"110px",
        rules: {
            id :[
              {required: true, message: '请输入序号', trigger: 'blur'},],
            orderid :[
              {required: true, message: '请输入订单id', trigger: 'blur'},],
            userid :[
              {required: true, message: '请输入用户id', trigger: 'blur'},],
            username :[
              {required: true, message: '请输入用户名', trigger: 'blur'},],
            password :[
              {required: true, message: '请输入密码', trigger: 'blur'},],
            expiration :[
              {required: true, message: '请输入有效期', trigger: 'blur'},],
            country :[
              {required: true, message: '请输入国家', trigger: 'blur'},],
            charge :[
              {required: true, message: '请输入负责人', trigger: 'blur'},],
            email :[
              {required: true, message: '请输入邮箱', trigger: 'blur'},],
            type :[
              {required: true, message: '请输入商品类型', trigger: 'blur'},],
            month :[
              {required: true, message: '请输入续费月数', trigger: 'blur'},],
            autorenew :[
              {required: true, message: '请输入是否自动续费', trigger: 'blur'},],
            endtime :[
              {required: true, message: '请输入到期时间', trigger: 'blur'},],
            changetime :[
              {required: true, message: '请输入下次更换套餐时间', trigger: 'blur'},],
            remark :[
              {required: true, message: '请输入备注', trigger: 'blur'},],
            createTime :[
              {required: true, message: '请输入创建时间', trigger: 'blur'},],
            updateTime :[
              {required: true, message: '请输入更新时间', trigger: 'blur'},],
            ext1 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext2 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext3 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext4 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
        },
      }
    },
    methods:{
      hasRole(val){
        return this.hasPerm(val)
      },
      initForm() {//初始数据
        return {
          id : "", // 序号
          orderid : "", // 订单id
          userid : "", // 用户id
          username : "", // 用户名
          password : "", // 密码
          expiration : "", // 有效期
          country : "", // 国家
          charge : "", // 负责人
          email : "", // 邮箱
          type : "", // 商品类型
          month : "", // 续费月数
          autorenew : "", // 是否自动续费
          endtime : "", // 到期时间
          changetime : "", // 下次更换套餐时间
          remark : "", // 备注
          createTime : "", // 创建时间
          updateTime : "", // 更新时间
          ext1 : "", // 扩展字段
          ext2 : "", // 扩展字段
          ext3 : "", // 扩展字段
          ext4 : "", // 扩展字段
        }
      },
      handleEdit(row) {
        console.log(row)
        this.form = row
        this.openDialog();
        this.title = "修改信息"
        this.type = "update"
      },
      handleDelete(row) {
        this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.operatorDeleteUser(row)
        }).catch(() => {
            MessageInfo("已取消删除");
        });
      },
      async operatorDeleteUser(row) {
        let res = await deleteCarbuy(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelCarbuy(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let res;
        if (this.type == "insert") {
          res = await insertCarbuy(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updateCarbuy(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      },
        async getCountryinfoAllInfo() {
            let res = await getCountryinfoAll()
            if (res.status == "200") {
                this.countryList = res.data
            } else {
                MessageError(res.msg)
            }
        },
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.userinfo=JSON.parse(userInfo)
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token'),

          }
        }
        if (!this.hasRole('carbuy:update')&&!this.hasRole('carbuy:delete')){
          this.tableConfig.hasOperation=false
        }
        this.getCountryinfoAllInfo()
      }
    }
  }
</script>

<style scoped>

</style>
